@tailwind base;
@tailwind components;
@tailwind utilities;

.mobile-nav {
    @apply block md:hidden fixed top-14 py-8 w-full h-full bg-gray-900 duration-500;
}

nav li a {
    @apply px-6 py-5 text-lg;
}

nav li a:hover {
    color: #06B6D4;
}

.bg-primary {
    background: #0F172B;
    filter: saturate(4px);
}

.bg-secondery {
    background: #1E293B;
}

.bg-accent {
    background: #E11D48;
}

.text-accent {
    color: #06B6D4;
}